<template>
  <div class="select input-group input-group-static">
    <label v-if="label">
      {{ label }}
    </label>

    <v-select
      v-model="modelValueHandler"
      class="select__input"
      :disabled="disabled"
      :options="options"
    >
      <template #search="{attributes, events}">
        <input
          class="vs__search form-control"
          :required="required && !modelValueHandler"
          v-bind="attributes"
          v-on="events"
        />
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: "BaseSelect",

  components: {
    vSelect,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [
        Number,
        String,
      ],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
      validator: (value) => value
        .every(
          (item) => [
            'code',
            'label',
          ]
            .every(
              (key) => Object.keys(item).includes(key),
            )
        ),
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
  ],

  computed: {
    modelValueHandler: {
      get() {
        return this.options
          .find(({ code }) => code === this.modelValue);
      },
      set(value) {
        this.$emit(
          'update:modelValue',  
          (
            typeof value === 'object'
            && !!value
            && 'code' in value
              ? value.code
              : value
          ),
        )
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import '~vue-select/dist/vue-select.css';
@import '@/assets/scss/material-dashboard';

.select {
  --vs-border-width: 0;

  &__input {
    width: 100%;
  }

  :deep(.v-select) {

    .vs {

      &__dropdown-toggle,
      &__selected-options,
      &__selected,
      &__search {
        padding: 0;
        margin: 0;
      }

      &__search {
        padding: $input-padding-y $input-padding-x;
      }

      &__selected,
      &__actions {
        position: absolute;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
      }

      &__selected {
        left: 0;
        color: inherit;
      }

      &__actions {
        right: 0;
      }

      &__clear,
      &__open-indicator {
        fill: currentColor;
      }

      &__clear {
        color: inherit;
      }

      &__dropdown-menu {
        color: $input-color;
      }
    }
  }
}
</style>
